<template>
    <video
        ref="backgroundVideo"
        class="video-jumbo"
        :class="videoClasses"
        :src="video"
        :poster="videoPoster"
        disablePictureInPicture="true"
        preload="none"
        autoplay
        muted
        loop
        playsinline
    ></video>
</template>

<script>
export default {
    name: 'VideoJumbo',
    props: {
        video: {
            required: false,
            type: String,
            default: null,
        },
        videoPoster: {
            required: false,
            type: String,
            default: null,
        },
        videoPosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        videoOpacity: {
            required: false,
            type: String,
            default: 'opacity-100',
        },
    },
    computed: {
        videoClasses () {
            const cls = {};
            cls['absolute inset-0 -z-10 h-full w-full object-cover'] = true;
            cls[this.videoPosition] = true;
            cls[this.videoOpacity] = true;
            return cls;
        },
    },
};
</script>
