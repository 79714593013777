<template>
    <section class="section-jumbo" :class="color">
        <div class="relative isolate overflow-hidden">
            <ImageJumbo
                class="-z-20"
                v-if="image"
                :image="image"
                :imageAlt="imageAlt"
                :imagePosition="imagePosition"
                :imageOpacity="imageOpacity"
                :imageLoading="imageLoading"
                :fetchPriority="fetchPriority"
            ></ImageJumbo>
            <VideoJumbo
                class="-z-20"
                v-if="video"
                :video="video"
                :videoPoster="videoPoster"
                :videoPosition="videoPosition"
                :videoOpacity="videoOpacity"
            ></VideoJumbo>
            <div
                v-if="fadeToBlack"
                class="absolute inset-0 -z-10 bg-gradient-to-b from-black/70 from-70% to-black/100 to-100%"
            ></div>
            <LayoutContained :class="innerClasses" class="z-0">
                <ContentHeadline
                    v-if="heading"
                    :heading="heading"
                    :headingColor="headingColor"
                    :headingComponent="headingComponent"
                    :marginBottom="content ? 'mb-6' : 'mb-0'"
                    :textCentered="textCentered"
                ></ContentHeadline>
                <ContentText
                    v-if="content"
                    :html="content"
                    :contentColor="contentColor"
                    :textCentered="textCentered"
                ></ContentText>
                <slot name="customTwo"></slot>
                <div v-if="buttonText" class="mt-8">
                    <ButtonPrimary
                        :buttonColor="buttonColor || undefined"
                        :buttonRoute="buttonRoute"
                        :buttonHref="buttonHref"
                        :buttonTarget="buttonTarget"
                        :buttonText="buttonText"
                        :buttonIcon="buttonIcon"
                        :buttonClick="buttonClick"
                        :buttonClickParams="buttonClickParams"
                    ></ButtonPrimary>
                </div>
                <slot name="custom"></slot>
            </LayoutContained>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SectionJumbo',
    props: {
        padding: {
            required: false,
            default: 'py-20 sm:py-24 lg:py-32',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonTarget: {
            required: false,
            type: String,
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonIcon: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-900',
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        fadeToBlack: {
            required: false,
            type: Boolean,
            default: false
        },
        imagePosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        imageOpacity: {
            required: false,
            type: String,
            default: 'opacity-100',
        },
        video: {
            required: false,
            type: String,
            default: null,
        },
        videoPoster: {
            required: false,
            type: String,
            default: null,
        },
        videoPosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        videoOpacity: {
            required: false,
            type: String,
            default: 'opacity-100',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: true,
        },
        imageLoading: {
            required: false,
            type: String,
            default: undefined,
        },
        fetchPriority: {
            required: false,
            type: [String, undefined],
            default: undefined,
        },
    },
    computed: {
        innerClasses () {
            const cls = {};
            cls[this.padding] = true;
            cls['text-center'] = !!this.textCentered;
            return cls;
        },
    },
};
</script>
