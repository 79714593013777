<template>
    <ImageOptimized
        v-bind="$attrs"
        :src="image"
        :alt="imageAlt"
        :class="imageClasses"
        :placeholder="[16, 9]"
        sizes="320px sm:640px md:768px lg:1100px xl:1280px 2xl:1536px"
        :quality="80"
        :loading="imageLoading"
        :fetchpriority="fetchPriority"
    />
</template>

<script>
export default {
    name: 'ImageJumbo',
    props: {
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imagePosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        imageOpacity: {
            required: false,
            type: String,
            default: 'opacity-100',
        },
        imageLoading: {
            required: false,
            type: String,
            default: 'eager', // eager, lazy
        },
        fetchPriority: {
            required: false,
            type: String,
            default: 'high', // high, low, auto
        },
    },
    computed: {
        imageClasses () {
            const cls = {};
            cls['absolute inset-0 -z-10 h-full w-full object-cover'] = true;
            cls[this.imagePosition] = true;
            cls[this.imageOpacity] = true;
            return cls;
        },
    },
};
</script>
